import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function TermPopUp() {
  const [openTerm, setOpenTerm] = React.useState(false);

  const handleClose = () => setOpenTerm(false);
  const handleShow = () => setOpenTerm(true);

  return (
    <>
      <u
        id="myBtn"
        className="cursor"
        onClick={() => {
          setOpenTerm(true);
        }}>
        Privacy Policy and Conditions
      </u>

      {openTerm ? (
        <Modal show={openTerm} onHide={handleClose} animation={false} >
          <Modal.Header>
            <Modal.Title>Privacy Policy and Conditions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>
              Welcome to IJM Land Berhad
              <br />
            </h4>
            <h5>
              (collectively referred to herein as "IJML", "we," "us," or "our")
              website.
            </h5>
            <br />
            <div className="modalText">
            All copyright, trademarks, and other intellectual property rights
            used as part of this website are vested in IJML. You acquire no
            rights in the website other than the limited right to use the
            website in accordance with these terms. You are permitted to print
            out or download information and content from this website for your
            own personal, non-commercial use. You may not offer for sale or sell
            or distribute over any medium, any part of this website or its
            content. You may not make any part of the website available as part
            of another website, whether by hyperlink framing on the Internet or
            otherwise unless you have been authorised to do so in writing by
            IJML. IJML reserves the right at any time and without notice to
            enhance, modify, alter, suspend or permanently discontinue all or
            any part of this website and to restrict or prohibit access to it.
            IJML does not warrant in any way, whether expressly or impliedly,
            the accuracy, reliability and completeness of the information and
            contents of this website. In no event, will IJML or any of its
            subsidiary or associated companies or related corporations or their
            directors and employees accept any liability with regard to your use
            of this website and/or to the information contained in the website.
            Your sole remedy is to discontinue using this website. This website
            and its contents do not in any way constitute any offer or
            invitation in respect of the shares in and securities of IJML or any
            other company. If you click on a link to a third party site, IJML
            cannot accept responsibility for any use of your personal
            information by such third parties, and we cannot guarantee that they
            will adhere to the same privacy and security practices as IJML. We
            encourage you to review the privacy policies of any other service
            provider from whom you request services. If you visit a third party
            website that is linked to IJML site, you should consult the site's
            privacy policy before providing your personal information. We
            reserve the right at our sole discretion to make changes in this
            policy without prior notice. These terms and conditions and the
            content of this website are governed by Malaysian law, and Malaysian
            courts shall have exclusive jurisdiction in any dispute.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}
