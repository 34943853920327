import { useState, useEffect } from "react";
import Axios from "axios";
import TermPopUp from "./TermPopUp";

export default function Register({ registered, setRegister }) {

  const [regData, setRegData] = useState({
    email: "",
    full_name: "",
    mobile: "",
  });


  function handleSubmit(e) {
    e.preventDefault();

    let date = new Date().toString();

    if (regData.email && regData.full_name && regData.mobile) {

      Axios.post("https://api.thelightcity.com.my/interested", {
        email: regData.email,
        full_name: regData.full_name,
        mobile: regData.mobile,
        date: date,
      }).then((err, res) => {
        if (err) throw err;
        console.log(res);
      });
      setRegister(true);
      console.log(regData);
    }
  }



  return (
    <div className="form-container">
      <h2>Register Your Interest</h2>
      {registered ? (
        <h5>
          You've successfully registered your interest on IJM Corporation Project.
          Our Agent will get back to you soon possible. Thank you!
        </h5>
      ) : (
        <form onSubmit={(e) => handleSubmit(e)} method="POST">
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Email</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="email"
              value={regData.email}
              onChange={(e) =>
                setRegData({ ...regData, email: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Full Name</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="text"
              value={regData.full_name}
              onChange={(e) =>
                setRegData({ ...regData, full_name: e.target.value })
              }
            />
          </div>
          <div className="inputContainer">
            <label className="inputTitle">
              <p>Phone Number</p>
            </label>
            <label className="inputSeparator">
              <p>:</p>
            </label>
            <input
              required
              type="number"
              value={regData.mobile}
              onChange={(e) =>
                setRegData({ ...regData, mobile: e.target.value })
              }
            />
          </div>
          <br></br>

          {/* <TermPopUp/> */}

          <div className="inputContainer">
            <input className="inputTitle" type="checkbox" required/>
            <div className="inputSeparator"></div>
            <p align="left">
            I confirm that I understand and accept your <TermPopUp/> of use of website and hereby consent to IJM Corporation Sdn. Bhd. and/or its subsidiary companies to collect my personal data provided herein for the purpose of communicating to me your products and services.
          </p></div>

          <button className="submit">Register</button>
         
        </form>
      )}
    </div>
  );
}
